'use client';
import {
  deleteFollowEvent,
  postFollowEvent,
} from '@requests/get-pages-content/events';
import { v2Links } from '@utils/navigation/links';
import { TeamProps } from '@v2/types';
import { AlertToast } from '@v2/ui';
import { AlertTriangle, Star01 } from '@v2/ui/Icon';
import { Spinner } from '@v2/ui/Icon/Spinner/Spinner';
import clsx from 'clsx';
import { kebabCase } from 'lodash';
import Link from 'next/link';
import { useRouter } from 'next/navigation';
import { useState } from 'react';
import toast from 'react-hot-toast';

export const TeamItem = ({
  team,
  isFavorite,
  userToken,
  onFavoriteUpdate,
  onRemoveFavoriteUpdate,
}) => {
  const starButtonClasses = clsx('cursor-pointer ml-3 group-hover:visible', {
    'visible md:invisible': !isFavorite,
    visible: isFavorite,
  });

  const router = useRouter();
  const [isLoading, setIsLoading] = useState(false);

  const onFavoriteClick = async (team: TeamProps) => {
    if (!userToken) {
      return router.push(v2Links.login);
    }

    if (isFavorite) {
      return onUnfavoriteClick(team);
    }

    const { teamId, team: name } = team;
    const type = 'performer';
    setIsLoading(true);

    const following = await postFollowEvent(
      Number(teamId),
      'performer',
      name,
      userToken
    );

    if (!following.error) {
      onFavoriteUpdate({ id: teamId, type: type.slice(0, 1), name });

      setIsLoading(false);
    } else {
      toast.custom(
        (t) => (
          <AlertToast
            {...t}
            title="Oops, something went wrong"
            content={'Please try again in a minute.'}
            icon={<AlertTriangle />}
          />
        ),
        { position: 'top-right' }
      );
    }

    setIsLoading(false);
  };

  const onUnfavoriteClick = async (team: TeamProps) => {
    setIsLoading(true);

    const { teamId, team: name } = team;

    const unfollow = await deleteFollowEvent(teamId, 'performer', userToken);

    if (!unfollow.error) {
      onRemoveFavoriteUpdate({
        id: teamId,
        type: 'performer',
        name: name,
      });
      setIsLoading(false);
    } else {
      toast.custom(
        (t) => (
          <AlertToast
            {...t}
            title="Oops, something went wrong"
            content={'Please try again in a minute.'}
            icon={<AlertTriangle />}
          />
        ),
        { position: 'top-right' }
      );
    }

    setIsLoading(false);
  };

  return (
    <li className="group relative mb-2 flex">
      <Link
        prefetch={false}
        href={`/events/${team.league?.toLowerCase()}/${kebabCase(team.team)}`}
        className="text-sm font-semibold text-gray-800"
      >
        {team.team}
      </Link>
      {isLoading ? (
        <Spinner className="ml-3 mt-1" size="12" />
      ) : (
        <button
          onClick={() => {
            onFavoriteClick(team);
          }}
          className={starButtonClasses}
        >
          <Star01
            size="16"
            fill={isFavorite ? '#667085' : ''}
            pathProps={{ stroke: '#667085' }}
          />
        </button>
      )}
    </li>
  );
};
